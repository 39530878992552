type Product = {
  price: number;
  discountPrice: number;
};

// Должна являтся чистой функцией, чтобы ошибки не возникали
export const getProductOldPrice = (product: Product) =>
  product.discountPrice !== product.price ? product.price : undefined;

export const getProductPrice = (product: Product) =>
  product.discountPrice !== product.price
    ? product.discountPrice
    : product.price;
